<template>
    <div class="row">
        <div class="col-12" v-if="!isLoad">
            <div v-if="exist" class="row">
                <div class="col-12" v-if="assignment != null">
                    <div class="py-5 card bg-white border-0 shadow-xss rounded-lg overflow-hidden">
                        <div class="d-flex flex-row  border-0 w-100 p-0 mb-3 shadow-none">
                            <div class="mx-4 my-1 p-0 ml-1 btn-round-md rounded-xl bg-current">
                                <i class="fas fa-clipboard-check font-md text-white"></i>
                            </div>
                            <div class="card-body p-0 pb-2 border-bottom border-dark">
                                <div class="row justify-content-between col-md-12">
                                    <h6 class="fw-600 text-grey-500 font-xssss">{{assignment.created_at | formatDate}}</h6>
                                    <h6 class="fw-700 text-grey-700 font-xssss">{{assignment.created_by?.name || '-'}}</h6>
                                </div>
                                <div class="row justify-content-between col-md-12">
                                    <h2 class="font-lg text-grey-900 fw-600">{{assignment.title}}</h2>
                                    <h4 class="font-xss text-grey-800 fw-700">Due {{assignment.due_date | formatDate}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-11 ml-5 pl-5">
                            <h4 class="text-grey-800 font-xs mt-4 fw-700">Description :</h4>
                            <!-- <p class="font-xsss fw-600 lh-28 text-grey-700 mb-0 pl-0">Time Given : 09:30 - 11:30</p><br> -->
                            <p class="font-xsss fw-600 lh-28 text-grey-600 mb-0 pl-0">{{assignment.description}}</p>
                            <div class="float-right">
                                <router-link v-if="!assignment.submission_obj" :to="{name:'MyCourseAssessmentTaskDiscussionStart', params:{idCourse : paramsId, idAssignment: assignment.id}}" class="bg-current border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w200">Start</router-link>
                                <div v-else>
                                    <div v-if="assignment.submission_obj?.status == 0">
                                        <div v-if="assignment.submission_obj.score" class="bg-success border-0 text-white fw-600 text-uppercase font-xssss rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w150 mr-2">Score : {{assignment.submission_obj.score | numfor}}</div>
                                        <router-link :to="{name:'MyCourseAssessmentTaskDiscussionStart', params:{idCourse : paramsId, idAssignment: assignment.id}}" class="bg-current border-0 text-white fw-600 text-uppercase font-xssss rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w150 mr-2">Continue</router-link>
                                        <a @click="!assignment.submission_obj.score ? changeStatus(1) : ''" href="javascript:void(0)" class="bg-success border-0 text-white fw-600 text-uppercase font-xssss rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w150">Submit</a>
                                    </div>
                                    <div v-else>
                                        <div v-if="assignment.submission_obj.score" class="bg-success border-0 text-white fw-600 text-uppercase font-xssss rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w150 mr-2">Score : {{assignment.submission_obj.score | numfor}}</div>
                                        <div class="bg-current border-0 text-white fw-600 text-uppercase font-xssss rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w150 mr-2">Finished</div>
                                        <a @click="!assignment.submission_obj.score ? changeStatus(0) : ''" v-if="!assignment.submission_obj.score" href="javascript:void(0)" class="bg-danger border-0 text-white fw-600 text-uppercase font-xssss rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w150">Unsubmit</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-11 ml-5 pl-5 mt-5" v-if="!isLoadingAnswers && assignment.submission_obj?.answer.length">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">  
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12 mb-2">
                                                    <h4>Questions</h4>
                                                </div>
                                                <div class="col-12">
                                                    <button v-for="(item, i) in assignment.submission_obj.answer" :disabled="disButton" :key="i" @click="setAnswer(i)" :class="{'btn-success' : answer == i, 'btn-outline-success' : answer != i}" class="btn w50 h50 col mr-2 mb-2 ">{{i + 1}}</button>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card p-0">
                                        <div class="row" v-for="(item, index) in assignment.submission_obj.answer" :key="index">
                                            <div class="col-12" v-show="answer == index">
                                                <div class="card-body p-0">
                                                    <div :id="'accordion1-'+index" class="accordion mb-0">
                                                        <div class="card shadow-xss mb-0">
                                                            <div class="card-header border-radius-none bg-current" :id="'heading1-'+index">
                                                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link text-white collapsed" data-toggle="collapse" :data-target="'#collapse1-'+index" aria-expanded="false" :aria-controls="'collapse1-'+index">Question - {{index + 1}}</button></h5>
                                                            </div>
                                                            <div :id="'collapse1-'+index" class="collapse" :aria-labelledby="'heading1'+index" :data-parent="'#accordion1-'+index">
                                                                <div class="card-body text-center" v-if="item.quest.quest_type == 1">
                                                                    <video class="w-50 h-auto" controls>
                                                                        <source :src=" item.quest.path" type="video/mp4">
                                                                    </video>
                                                                </div>
                                                                <div class="card-body" v-if="item.quest.quest_type == 2">
                                                                    <audio class="w-100" controls>
                                                                        <source :src=" item.quest.path" type="audio/mpeg">
                                                                    </audio>
                                                                </div>
                                                                <div class="card-body ql-editor">
                                                                    <div v-html="item.quest.question.replaceAll('[BLANK]', '[_____]')"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0" v-if="assignment.submission_obj.score != null">
                                                    <div :id="'accordion2-'+index" class="accordion mb-0">
                                                        <div class="card shadow-xss mb-0">
                                                            <div class="card-header bg-greylight theme-dark-bg" :id="'heading-2'+index">
                                                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" :data-target="'#collapse2-'+index" aria-expanded="false" :aria-controls="'collapse2-'+index">Answer</button></h5>
                                                            </div>
                                                            <div :id="'collapse2-'+index" class="collapse" :aria-labelledby="'heading2-'+index" :data-parent="'#accordion2-'+index">                
                                                                <div v-if="item.quest.submission_type == 1" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                                                    <a :href=" item.answer" target="_blank" class="btn btn-success"><i class="fas fa-file mr-2"></i>File Answer</a>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 2" style="width: 100wh" class="card-body ql-editor p-3 border-top bg-lightgrey">
                                                                    <div v-html="item.answer"></div>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 3" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                                                    <table class="table m-0">
                                                                        <tr v-for="(opt, j) in item.quest.option" :key="j">
                                                                            <td width="5%" v-if="opt.id == item.answer && item.quest.answer == item.answer" class="bg-success text-white"><i v-if="item.quest.answer == opt.id" class="fas fa-check"></i></td>
                                                                            <td width="5%" v-else-if="opt.id == item.answer && item.quest.answer != item.answer" class="bg-danger"><i v-if="item.quest.answer == opt.id" class="fas fa-check"></i></td>
                                                                            <td width="5%" v-else class="bg-grey text-success"><i v-if="item.quest.answer == opt.id" class="fas fa-check"></i></td>
                                                                            <td>{{opt.option}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 4" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                                                    <a href="javascript:void(0)" :class="{'btn-success' : item.answer == '1', 'btn-outline-success' : item.answer != '1'}" class="btn btn-lg mr-2">True<i class="fas ml-2" :class="{'fa-check': '1' == item.quest.answer, 'fa-times': '1' != item.quest.answer}"></i></a>
                                                                    <a href="javascript:void(0)" :class="{'btn-danger' : item.answer == '0', 'btn-outline-danger' : item.answer != '0'}" class="btn btn-lg">False<i class="fas ml-2" :class="{'fa-check': '0' == item.quest.answer, 'fa-times': '0' != item.quest.answer}"></i></a>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 5" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                                                    <table class="table m-0">
                                                                        <tr v-for="(opt, j) in item?.answer?.split('*##*')" :key="j">
                                                                            <td style="width: 1%;" class="bg-grey"><b>[BLANK]-{{j}}</b></td>
                                                                            <td>{{item.quest?.answer?.split('*##*')[j]}}</td>
                                                                            <td class="bg-grey">{{opt}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 6" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                                                    <table class="table m-0">
                                                                        <tr v-for="(opt, j) in item.quest.option" :key="j">
                                                                            <td class="bg-grey">{{opt.option}} ({{item.quest?.answer?.split('*##*')[j]}})</td>
                                                                            <td>{{item?.answer?.split('*##*')[j]}}</td>
                                                                            <td width="1%"><i class="fas ml-2" :class="{'fa-check text-success': item?.answer?.split('*##*')[j] == item.quest?.answer?.split('*##*')[j], 'fa-times text-danger': item?.answer?.split('*##*')[j] != item.quest?.answer?.split('*##*')[j]}"></i></td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 7" style="width: 100wh" class="card-body ql-editor border-top bg-lightgrey">
                                                                    <div v-html="item.answer"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0" v-else>
                                                    <div :id="'accordion2-'+index" class="accordion mb-0">
                                                        <div class="card shadow-xss mb-0">
                                                            <div class="card-header bg-greylight theme-dark-bg" :id="'heading-2'+index">
                                                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" :data-target="'#collapse2-'+index" aria-expanded="false" :aria-controls="'collapse2-'+index">Answer</button></h5>
                                                            </div>
                                                            <div :id="'collapse2-'+index" class="collapse" :aria-labelledby="'heading2-'+index" :data-parent="'#accordion2-'+index">                
                                                                <div v-if="item.quest.submission_type == 1" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                                                    <a :href=" item.answer" target="_blank" class="btn btn-success"><i class="fas fa-file mr-2"></i>File Answer</a>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 2" style="width: 100wh" class="card-body ql-editor p-3 border-top bg-lightgrey">
                                                                    <div v-html="item.answer"></div>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 3" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                                                    <table class="table m-0">
                                                                        <tr v-for="(opt, j) in item.quest.option" :key="j">
                                                                            <td width="5%" class="bg-grey text-success text-center"><i v-if="item.quest.answer == opt.id" class="fas fa-check"></i></td>
                                                                            <td>{{opt.option}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 4" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                                                    <a href="javascript:void(0)" :class="{'btn-success' : item.answer == '1', 'btn-outline-success' : item.answer != '1'}" class="btn btn-lg mr-2">True<i class="fas ml-2" :class="{'fa-check': '1' == item.quest.answer, 'fa-times': '1' != item.quest.answer}"></i></a>
                                                                    <a href="javascript:void(0)" :class="{'btn-danger' : item.answer == '0', 'btn-outline-danger' : item.answer != '0'}" class="btn btn-lg">False<i class="fas ml-2" :class="{'fa-check': '0' == item.quest.answer, 'fa-times': '0' != item.quest.answer}"></i></a>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 5" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                                                    <table class="table m-0">
                                                                        <tr v-for="(opt, j) in item?.answer?.split('*##*')" :key="j">
                                                                            <td style="width: 1%;" class="bg-grey"><b>[BLANK]-{{j}}</b></td>
                                                                            <td>{{item.quest?.answer?.split('*##*')[j]}}</td>
                                                                            <td class="bg-grey">{{opt}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 6" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                                                    <table class="table m-0">
                                                                        <tr v-for="(opt, j) in item.quest.option" :key="j">
                                                                            <td class="bg-grey">{{opt.option}} ({{item.quest?.answer?.split('*##*')[j]}})</td>
                                                                            <td>{{item?.answer?.split('*##*')[j]}}</td>
                                                                            <td width="1%"><i class="fas ml-2" :class="{'fa-check': item?.answer?.split('*##*')[j] == item.quest?.answer?.split('*##*')[j], 'fa-times': item?.answer?.split('*##*')[j] != item.quest?.answer?.split('*##*')[j]}"></i></td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div v-if="item.quest.submission_type == 7" style="width: 100wh" class="card-body ql-editor border-top bg-lightgrey">
                                                                    <div v-html="item.answer"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0" v-if="assignment.submission.score != null">
                                                    <div :id="'accordion3-'+index" class="accordion mb-0">
                                                        <div class="card shadow-xss mb-0">
                                                            <div class="card-header bg-greylight theme-dark-bg" :id="'heading-3'+index">
                                                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" :data-target="'#collapse3-'+index" aria-expanded="false" :aria-controls="'collapse3-'+index">Feedback</button></h5>
                                                            </div>
                                                            <div :id="'collapse3-'+index" class="collapse" :aria-labelledby="'heading3-'+index" :data-parent="'#accordion3-'+index">                
                                                                <div class="card-body">
                                                                    <form class="row">
                                                                        <div class="col-12">
                                                                            <div class="card border">
                                                                                <div class="card-header bg-current p-3">
                                                                                    <h5 class="fw-600 text-white">Rubric : {{item.rubrics.name}}</h5>
                                                                                </div>
                                                                                <div class="card-body py-0 px-1">
                                                                                    <table class="table mb-0 w-100">
                                                                                        <tbody>
                                                                                            <tr v-for="(crt, i) in item.rubrics.criteria" :key="i" class="p-auto">
                                                                                                <td width="80%" valign="middle" class="fw-600">
                                                                                                    <h2>
                                                                                                        {{crt.name}}
                                                                                                    </h2>
                                                                                                </td>
                                                                                                <td width="10%"><h1> {{crt.submit_score.score}} / {{crt.score}}</h1></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group col-12" v-if="item.message">
                                                                            <label for="">Message</label>
                                                                            <p v-html="item.message"></p>
                                                                        </div>
                                                                        <div class="form-group col-12" v-if="item.path">
                                                                            <label for="">Attachment</label><br>
                                                                            <a :href=" item.path" target="_blank" class="btn btn-success"><i class="fas fa-file mr-2"></i>See File</a>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="isLoadingAnswers" class="col-12">
                            <div class="row">
                                <div class="col-12 py-3 text-center">
                                    <div class="my-3">
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8 text-center default-page">
                            <div class="card border-0 text-center d-block">
                                <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                                <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                                <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                                <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" v-else>
            <div class="row">
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    filters: {
        numfor: function(x) {
            return parseFloat(x).toFixed(2);
        }
    },
    data() {
        return {
            paramsId: this.$route.params.idCourse,
            assignmentId: this.$route.params.idAssignment,
            isLoad: true,
            exist: true,
            assignment: {},
            answer: 0,
            disButton : false,
            media : process.env.VUE_APP_URL_CLOUD,
            isLoadingAnswers: true
        }   
    },
    created(){
        this.getAssigment()
    },
    methods:{
        setItemRef(i) {
            return `upload${i}`;
        },
        setAnswer(i) {
            this.answer = i
        },
        async getAssigment(){
            this.isLoadingAnswers = true
            await axios.get(`${process.env.VUE_APP_URL_API}/core/assignment/v2/summary?slug=${this.paramsId}&id=${this.assignmentId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then( async (res) => {
                if (res.data.success) {
                    this.assignment = res.data.data
                    this.isLoad = false
                    if(res.data.data.submission_obj?.id){
                        await axios.get(`${process.env.VUE_APP_URL_API}/core/assignment/v2/answers?submission_id=${res.data.data.submission_obj.id}`, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`
                            }
                        }).then( res => {
                            let tempData = this.assignment
                            tempData.submission_obj.answer = res.data.data
                            this.assignment = tempData
                            this.setAnswer(0)
                            this.isLoadingAnswers = false
                        })
                    }else{
                        this.isLoadingAnswers = false
                    }
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Discussion',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(err => {
                if (err?.response?.status == 404) {
                    this.isLoad = false
                    this.exist = false
                }
            })
        },
        async changeStatus(status){
            var data = {
                id: this.assignment.submission_obj.id,
                assignment_id: this.assignment.id,
                status: status

            }
            await axios.post(`${process.env.VUE_APP_URL_API}/core/assignment/quest/status`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    this.getAssigment()
                    this.$swal({
                        toast: true,
                        title: 'Discussion',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Discussion',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(err => {
                if (err.response?.status == 404) {
                    this.isLoad = false
                    this.exist = false
                }
            })
        }
    },
}
</script>